<template>
  <div class="news-root-container">
    <div class="news-carousel">
      <a-carousel class="news-swapper" :after-change="onChange" dotPosition="top" dotsClass="news-circle-dot" autoplay>
        <div class="news-swapper-item-container div-background" v-for="item in news_banner_list" :key="item.news_id"
          :style="{
            backgroundImage: `url(${item.img_url})`,
          }" @click="goDetail(item.news_id)">
          <img :src="icons.news_mask" class="news-mask" />
          <div class="news-swapper-content-container">
            <div class="news-swapper-item-title">{{ item.title }}</div>
            <div class="news-swapper-item-desc single-text">
              {{ item.short_desc }}
            </div>
          </div>
        </div>
      </a-carousel>
      <img :src="icons.news_vague" class="news-vague" />
    </div>

    <div class="news-nouvelles-en">Nouvelles</div>
    <div class="news-nouvelles-zh">最新动态</div>
    <img :src="icons.triangle" class="news-nouvelles-triangle" />

    <div class="news-list-container">
      <div class="news-item-container" v-for="item in news_list" :key="item.news_id" @click="goDetail(item.news_id)">
        <div class="news-item-img div-background" :style="{
            backgroundImage: `url(${item.img_url})`,
          }"></div>
        <div class="news-item-title single-text">{{ item.title }}</div>
        <div class="news-item-time">{{ item.create_time_format }}</div>
      </div>
    </div>
    <div class="news-btn-more div-background" :style="{
        backgroundImage: `url(${icons.news_btn_more_normal})`,
      }" @click="loadMore" v-if="news_list.length != news_total_count">
      点击加载更多
    </div>
    <div v-else class="news-no-more">已经到底了</div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as eventHelper from "../../utils/eventHelper";
import * as type from "../../store/type";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    this.$store.commit(type.CLEAR_NEWS_LIST);
    this.$store.commit(type.CLEAR_NEWS_BANNER_LIST);
    this.$store.commit(type.SET_NEWS_PAGE_INDEX, 0);

    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getBannerList();
    this.getList();
  },
  computed: {
    ...mapState({
      news_banner_list: (state) => state.news.news_banner_list,
      news_list: (state) => state.news.news_list,
      news_page_size: (state) => state.news.news_page_size,
      news_page_index: (state) => state.news.news_page_index,
      news_total_count: (state) => state.news.news_total_count,
    }),
  },
  methods: {
    ...mapActions({
      newsGetBannerListAction: "newsGetBannerList",
      newsGetListAction: "newsGetList",
    }),
    onChange() {},
    goDetail(id) {
      this.$router.push({
        name: "news-detail",
        params: {
          news_id: id,
        },
      });
    },
    getBannerList() {
      this.newsGetBannerListAction()
        .then(() => {})
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getList() {
      this.newsGetListAction({
        page_size: this.news_page_size,
        page_index: this.news_page_index,
      })
        .then(() => {})
        .catch((error) => {
          this.$message.error(error);
        });
    },
    loadMore() {
      this.$store.commit(type.SET_NEWS_PAGE_INDEX, this.news_page_index + 1);

      this.getList();
    },
  },
};
</script>

<style scoped>
.news-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
.news-carousel,
.news-swapper {
  position: relative;
  width: 100vw;
}
.news-circle-dot {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  margin-right: 12px !important;
}
.news-circle-dot:last-child {
  margin-right: 0px;
}
.news-swapper-item-container {
  position: relative;
  width: 100vw;
  height: 912px;
  cursor: pointer;
}
.news-swapper-content-container {
  position: absolute;
  left: 120px;
  bottom: 150px;
}
.news-swapper-item-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
}
.news-swapper-item-desc {
  width: 800px;
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 0;
  margin-top: 15px;
}
.news-mask {
  position: absolute;
  top: 0;
  /* left: 0;
  right: 0; */
  width: 100vw;
}
.news-vague {
  position: absolute;
  width: 100vw;
  bottom: 0;
}
.news-nouvelles-en {
  margin-top: 33px;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  text-align: center;
}
.news-nouvelles-zh {
  margin-top: 10px;
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #062c3e;
  letter-spacing: 0;
  text-align: center;
}
.news-nouvelles-triangle {
  margin-top: 30px;
  width: 18px;
  height: 15px;
}
.news-list-container {
  margin-top: 50px;
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.news-item-container {
  margin-bottom: 60px;
  margin-right: 40px;
  width: 240px;
  cursor: pointer;
}
.news-item-container:nth-child(3n + 0) {
  margin-right: 0px;
}
.news-item-img {
  width: 100%;
  height: 147px;
}
.news-item-title {
  margin-top: 20px;
  width: 100%;
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.news-item-time {
  margin-top: 5px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.news-btn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 60px;
  cursor: pointer;
  margin-bottom: 50px;
  user-select: none;
}
.news-btn-more:hover {
  background-image: url("https://iacc-1257406365.cos.ap-beijing.myqcloud.com/assets/news/btn_more_hover.png") !important;
}
.news-no-more {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 50px;
}
</style>